
.headings{
  color: #6a0dad; /* Purple color */
}
/* Custom CSS for the list items */
.custom-list li {
  padding: 10px 0; /* Space between items */
  font-size: 1.1em; /* Slightly larger font */
}
.first-character {
  font-weight: 400;
  float: center;
  font-size: 100px;
  line-height: 100px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  font-family: 'Source Sans Pro', sans-serif;
}

.sc {
  color: #3b8595;
}

.ny {
  color: #3d3c3a;
}

.atw {
  color: #c48660;
}
/* First Character Highlight */

.caption {
  font-size: 2em; /* Larger font size for emphasis */
  font-weight: bold;
  color: orangered; /* Golden color for highlight */
  display: inline; /* Ensure inline display */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  vertical-align: middle; /* Vertically align with text */
  line-height: 1; /* Keep line height consistent */
}

.block {
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto;   /* Center the block horizontally */
  padding: 20px;    /* Add some padding for spacing */
}

.line-break {
  border-top: 1px solid #ccc; /* Optional: adds a line break */
  margin: 20px 0;             /* Space above and below the line */
}

.margin-top-10 {
  margin-top: 10px;           /* Consistent margin for paragraphs */
}
.row {
  margin-bottom: 0; /* Remove bottom margin from the row */
}

.custom-list {
  margin-bottom: 0; /* Remove margin from the list */
}

.img-fluid {
  margin-top: 0; /* Remove any margin from the image */
}

@import "../../styles/common/ParallaxStyles.css"
