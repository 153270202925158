/* src/components/Navbar.css */
.navbar {
    background-color: #6a0dad; /* Purple color */
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000; /* Adjust as necessary */
  }
  
  .navbar-logo {
    color: white;
    font-size: 24px;
    font-weight: bold;
    background-color: white;
    max-width:175px; 
    max-height:75px;
  }
  
  .navbar-links {
    list-style-type: none;
    display: flex;
  }
  
  .navbar-links li {
    margin-left: 20px;
  }
  
  .navbar-links li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
  }
  
  .navbar-links li a:hover {
    color: #dcdcdc;
    text-decoration: underline;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  
  /* Optional: Styling for centering navbar links on desktop view */
  .navbar-nav {
    text-align: center;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 991.98px) {
    .navbar-nav {
      width: 100%;
    }
  }
 /* Custom styles for the toggle button */
.navbar-toggler {
  background-color: white; /* White background */
  border: 2px solid white; /* White border */
  border-color: white;
 
}



/* Create a custom icon */
.navbar-toggler:after {
  content: ''; /* Creates a new icon */
  display: block;
  width: 30px; /* Adjust width as needed */
  height: 3px; /* Adjust height as needed */
  background-color: white; /* Color for the lines */
  margin: 6px auto; /* Center the icon */
}

.navbar-toggler:before,
.navbar-toggler:after {
  content: '';
  display: block;
  width: 30px; /* Adjust width as needed */
  height: 3px; /* Adjust height as needed */
  background-color: white; /* Color for the lines */
  margin: 6px auto; /* Center the icon */
  border: 2px solid white; /* White border */
  border-color: white;
}

.navbar-toggler:before {
  margin-bottom: 6px; /* Space between the top line and middle line */
}

  .parallax-btn-prop {
    font-size: 16px; /* Adjust button text size */
    padding: 10px 20px; /* Adjust padding for a better look */
}
@import "../../../styles/common/ButtonStyles.css"