/* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Services Section */
  .services-section {
    padding: 40px 0;
  }
  
  .service-row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .service-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .service-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .service-img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  .service-img:hover {
    transform: translateY(-5px); /* Add a hover effect */
  }
  
  /* Before and After Section */
  .before-after-section {
    background-color: #f9f9f9;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .before-after-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  
  .before-after-row {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #ddd;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    width: 48%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .before-after-column {
    flex: 1;
    text-align: center;
    position: relative;
  }
  
  .before-after-img {
    width: 100%;
    border-radius: 8px;
  }
  
  .overlay-text {
    position: absolute;
    top: 8px;
    left: 16px;
    font-size: 1.2rem;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .sectionHeading__title {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 16px;
  }
  
  .section-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 32px;
  }
  /* Before and After Section */
.before-after-section {
    background-color: #f9f9f9;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .before-after-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  
  /* Before and After Rows */
  .before-after-row {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #ddd;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    width: 48%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .before-after-row:hover {
    transform: translateY(-5px); /* Add a hover effect */
  }
  
  /* Before and After Columns */
  .before-after-column {
    flex: 1;
    text-align: center;
    position: relative;
  }
  
  .before-after-img {
    width: 100%;
    max-width: 100%; /* Ensures the image stays within its container */
    border-radius: 8px;
  }
  
  .overlay-text {
    position: absolute;
    top: 8px;
    left: 16px;
    font-size: 1.2rem;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .sectionHeading__title {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 16px;
  }
  
  .section-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 32px;
  }
  
  /* Media Queries for Responsive Design */
  @media only screen and (max-width: 768px) {
    .before-after-row {
      flex-direction: column; /* Stack columns vertically on small screens */
      width: 100%; /* Full width on mobile */
      margin-bottom: 20px; /* Add spacing between rows */
    }
    
    .before-after-img {
      width: 100%; /* Ensure images fit within their containers */
      height: auto; /* Maintain aspect ratio */
    }
  
    .before-after-container {
      display: block;
      margin: 0 auto; /* Center container on small screens */
    }
  
    .sectionHeading__title {
      font-size: 1.8rem; /* Smaller font size for headings on mobile */
    }
  
    .section-description {
      font-size: 1rem; /* Smaller font size for descriptions on mobile */
    }
  }
  