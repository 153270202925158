/* Screening Button Style */
.btn.screening-btn {
  background-color: #28a745; /* Green color */
  color: white;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(40, 167, 69, 0.4);
}

.btn.screening-btn:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05);
  color: white;
}


/* Schedule Now Button Style */
.btn.schedule-btn {
  background: linear-gradient(45deg, #007bff, #00c6ff); /* Blue gradient */
  color: white;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.4);
}

.btn.schedule-btn:hover {
  background: linear-gradient(45deg, #0056b3, #00aaff); /* Darker gradient */
  transform: translateY(-3px); /* Bounce effect */
  color: white;
}

/* Call Now Button Style */
.btn.call-btn {
  background-color: #3e2ce4; 
  color: white;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(136, 118, 218, 0.4);
  
}

.btn.call-btn:hover {
  background-color:  #3e2ce4;  /* Darker red on hover */
  box-shadow: 0 6px 16px rgba(136, 118, 218, 0.4);/* Enhanced shadow */
  transform: scale(1.05);
  transform: translateY(-3px);
  color: white;
}
/* Call Now Button Style */
.btn.No-btn {
  background-color:rgba(255, 208, 0, 0.648); 
  color: white;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(136, 118, 218, 0.4);
  
}

.btn.No-btn:hover {
  background-color: rgba(255, 208, 0, 0.648);   /* Darker red on hover */
  box-shadow: 0 6px 16px rgba(136, 118, 218, 0.4);/* Enhanced shadow */
  transform: scale(1.05);
  transform: translateY(-3px);
  color: white;
}