/* src/components/Footer.css */

.footer {
    background-color: #4b0082; /* Dark purple */
    color: white;
    padding: 40px 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  
  .footer-section {
    flex: 1;
    margin: 20px;
  }
  
  .footer-section h3 {
    color: #ffccff; /* Light purple */
    margin-bottom: 15px;
  }
  
  .footer-section p, .footer-section a {
    margin: 5px 0;
    color: white;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  .map-container iframe {
    border: 0;
  }
  
  .social-icons {
    display: flex;
    gap: 20px;
    font-size: 24px;
  }
  
  .social-icons a {
    color: white;
    transition: color 0.3s ease;
    justify-content: center;
  }
  
  .social-icons a:hover {
    color: #ffccff;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    background-color: #36006b; /* Even darker purple */
    color: white !important; /* Force the text to be white */
}

  
  .footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
    color: white !important;
  }
  