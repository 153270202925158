.parallax {
    position: relative;
    height: 100vh; /* Full-screen height */
    background-image: url('../../assets/images/Legsinwaterside.jpg'); /* Replace with your image */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* Title Styling */
  .parallax-title {
    font-size: 3em; /* Larger font for the title */
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  /* Subtitle Styling */
  .parallax-text {
    font-size: 1.5em;
    line-height: 1.6;
    margin: 20px 0;
    color: white;
  }
   
  .parallax-1 {
    background-image: url('../../assets/images/Legsinwaterside.jpg'); /* Replace with your image path */
  }
  
  .parallax-2 {
    background-image: url('../../assets/images/LegsinBeach.jpg'); /* Replace with your image path */
  }
  
  .parallax-3 {
    background-image: url('../../assets/images/Legsupcrossed.jpg'); /* Replace with your image path */
  }
  
  .parallax-content {
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    padding: 40px;
    border-radius: 10px;
    color: #fff;
    max-width: 800px; /* Adjust width as needed */
    margin: 0 auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  }
  .parallax-content {
    color: #fff; /* Ensure text color is white for visibility */
    max-width: 800px; /* Restrict width for readability */
    margin: 0 auto; /* Center-align content */
  }
  .parallax-text {
    font-size: 1.5em;
    line-height: 1; /* Adjust line height for text */
    
  }
  .parallax-btn-prop {
    font-size: 16px; /* Adjust button text size */
    padding: 10px 20px; /* Adjust padding for a better look */
  }
  /* Call-to-Action Button Styling */
  .parallax-btn-container {
    margin-top: 20px;
  }
  
  .parallax-btn {
    padding: 15px 30px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .parallax-btn:hover {
    background-color: #0056b3; /* Darker shade on hover */
    text-decoration: none;
    transform: scale(1.05); /* Slight enlargement */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .parallax-title {
      font-size: 2em;
    }
    .parallax-text {
      font-size: 1.2em;
    }
    .parallax-btn {
      padding: 12px 24px;
    }
  }


.top-left-text {
  position: absolute; /* Use absolute positioning */
  top: 10px;          /* Adjusts distance from the top */
  left: 10px;         /* Adjusts distance from the left */
  margin: 0;          /* Remove any default margins */
  padding: 0;         /* Remove any default padding */
  text-align: left;   /* Align the text to the left */
  color: #fff;        /* Set text color */
   /* Adjust font size as needed */
       /* Ensure it's on top of other elements */
}

.parallax-btn-sc {
  position: absolute;
  top: 45%; /* Centers vertically */
  left: 60%; /* Centers horizontally */
  transform: translate(-50%, -50%); /* Adjusts position for perfect centering */
}
.top-left-text-1 {
  left: 10px;
  padding: 2px;
}