/* src/App.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

p {
  color: #666;
  font-size: 16px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

form input, form textarea, form select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

form input[type="radio"], form input[type="checkbox"] {
  width: auto;
}

form button {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

form button:hover {
  background-color: #45a049;
}

form label {
  font-weight: bold;
  color: #444;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #4CAF50;
  color: white;
  margin-top: 20px;
}
.no-bullets {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Remove left padding */
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
    padding: 10px;
  }

  .navbar-links li {
    margin-left: 5px;
  }
}
@import "../src/styles/common/ButtonStyles.css"